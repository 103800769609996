import React, { useState, useEffect } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useProperties } from "../../context/properties.context";

const Amenities = ({ amenities, applyFilters, clear }) => {
  const { urlFilters } = useProperties();
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const [amenitiesSelected, setAmenitiesSelected] = useState([]);

  useEffect(() => {
    if (clear) {
      setAmenitiesSelected([]);

      return;
    }

    if (Object.keys(urlFilters).length && urlFilters.amenities) {
      const selectedAmenities = new Set(amenities.reduce((acc, cur) => {
        urlFilters.amenities.forEach((a) =>
          cur.itemIDs.includes(a) ? acc.push(cur.name) : null
        );

        return acc;
      }, []));
      
      setAmenitiesSelected(Array.from(selectedAmenities));
    }
  }, [clear]);

  const toggleListVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleAmenityClick = (item) => {
    const newAmenitiesSelected = new Set(amenitiesSelected);

    if (newAmenitiesSelected.has(item.name)) {
      newAmenitiesSelected.delete(item.name);
    } else {
      newAmenitiesSelected.add(item.name);
    }

    const updatedAmenitiesSelected = Array.from(newAmenitiesSelected);
    setAmenitiesSelected(updatedAmenitiesSelected);

    const filteredAmenities = amenities.filter((amenity) =>
      updatedAmenitiesSelected.includes(amenity.name)
    );

    const mergedItemIDs = filteredAmenities.reduce(
      (accumulator, current) => [...accumulator, ...current.itemIDs],
      []
    );
    applyFilters({ amenities: mergedItemIDs });
  };

  const classDiv = `filter_amenities ${isVisible ? "active" : ""}`;

  const calculateIconClass = (item) => {
    return amenitiesSelected.includes(parseInt(item.id))
      ? "icon active"
      : "icon";
  };

  return (
    <div ref={ref} className={classDiv}>
      <h3
        className="filter_heading"
        data-group="filters"
        onClick={toggleListVisibility}
      >
        Amenities
        {amenitiesSelected.length > 0 && (
          <span className="filter-badge">{amenitiesSelected.length}</span>
        )}
        <span className="drop-indicator">
          <svg className="icon icon-chevron-down">
            <use xlinkHref="#icon-chevron-down"></use>
          </svg>
        </span>
      </h3>

      <div className="filter_list wide">
        <ul>
          {amenities &&
            amenities.map((item, idx) => {
              return (
                <li
                  key={`amenity-${idx}`}
                  data-id={item.itemIDs}
                  data-group="filter-items"
                  className="amenity"
                  onClick={() => handleAmenityClick(item)}
                >
                  <svg
                    className={calculateIconClass(item)}
                    data-group="check-mark"
                  >
                    <use
                      xlinkHref={
                        amenitiesSelected.includes(item.name)
                          ? "#icon-check-active"
                          : "#icon-check-empty"
                      }
                    ></use>
                  </svg>
                  <span>{item.name}</span>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default Amenities;
