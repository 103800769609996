import React from "react";
import { Tooltip } from "antd"

const AmenitiesCard = ({ property, globalSettings }) => {
  function hasFeature(property, item) {
    let filterIDs = property.filter;
    try {
      if (filterIDs != undefined) {
        for (let i = 0; i < filterIDs.length; i++) {
          if (parseInt(filterIDs[i], 10) == parseInt(item.id, 10)) {
            return "icon" + item.icon + " active";
          }
        }
      }
    } catch (err) {
      // console.log(err, property.name + "(" + property.id + ")");
    }

    return "icon" + item.icon;
  }

  return (
    <div className="property-card_amenities">
      <ul>
        {globalSettings?.feature_filters?.map((item, idx) => {
          return (
            <li key={`key-${idx}`} data-ref={item.id} className={hasFeature(property, item)}>
              <Tooltip title={item.name} placement="bottom">
                <svg className={hasFeature(property, item)}>
                  <use xlinkHref={`#icon-${item.icon}`}></use>
                </svg>

                {item.name.includes("Eco-Friendly") && (
                  <svg
                    className={`icon icon-vtt-eco ${hasFeature(property, item)}`}
                    title={item.name}
                    style={{
                      maxWidth: "15px",
                      maxHeight: "15px"
                    }}
                  >
                    <use xlinkHref="#icon-vtt-eco"></use>
                  </svg>
                )}
              </Tooltip>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AmenitiesCard;
