import React from "react";
import ContentLoader from "react-content-loader";

const PropertyLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={700}
      height={200}
      viewBox="0 0 700 200"
      backgroundColor="#c8c8c8"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="20" y="20" rx="10" ry="10" width="240" height="130" />
      <rect x="280" y="30" rx="4" ry="4" width="250" height="20" />
      <rect x="280" y="60" rx="4" ry="4" width="100" height="15" />      
      <rect x="280" y="90" rx="4" ry="4" width="20" height="15" />
      <rect x="310" y="90" rx="4" ry="4" width="20" height="15" />
      <rect x="340" y="90" rx="4" ry="4" width="20" height="15" />
      <rect x="600" y="50" rx="5" ry="5" width="50" height="40" />
      <rect x="580" y="110" rx="3" ry="3" width="100" height="25" />
    </ContentLoader>
  );
};

export default PropertyLoader;
