import React, { useEffect, useState } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useProperties } from "../../context/properties.context";

export default function FeatureFilters({ applyFilters, clear }) {
  const { urlFilters } = useProperties();
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const [featuresSelected, setFeatureSelected] = useState([]);

  const toggleListVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (clear) {
      setFeatureSelected([]);

      return
    }

    if (Object.keys(urlFilters).length && urlFilters.features) {
      setFeatureSelected(urlFilters.features);
    }
  }, [clear]);

  const handleFeatureClick = (feature) => {
    let features;
    let id = parseInt(feature.id);
    if (featuresSelected.includes(id)) {
      features = featuresSelected.filter((f) => f !== id);
    } else {
      features = [...featuresSelected, id];
    }
    setFeatureSelected(features);
    applyFilters({ features });
  };

  const classDiv = `filter_features ${isVisible ? "active" : ""}`;

  const calculateIconClass = (item) => {
    return featuresSelected.includes(parseInt(item.id))
      ? "icon active"
      : "icon";
  };

  return (
    <div ref={ref} className={classDiv}>
      <h3
        className="filter_heading"
        data-group="filters"
        onClick={toggleListVisibility}
      >
        {globalSettings.feature_filter_title || "Local Highlights"}
        {featuresSelected.length > 0 && (
          <span className="filter-badge">{featuresSelected.length}</span>
        )}
        <span className="drop-indicator">
          <svg className="icon icon-chevron-down">
            <use xlinkHref="#icon-chevron-down"></use>
          </svg>
        </span>
      </h3>

      <div className="filter_list wide">
        <ul>
          {globalSettings.feature_filters.map((item, idx) => {
            return (
              <li
                key={`feature-${idx}`}
                data-group="filter-items"
                className="feature"
                data-id={item.id}
                data-family="parent"
                onClick={() => handleFeatureClick(item)}
              >
                <svg
                  className={calculateIconClass(item)}
                  data-group="check-mark"
                >
                  <use
                    xlinkHref={
                      featuresSelected.includes(parseInt(item.id))
                        ? "#icon-check-active"
                        : "#icon-check-empty"
                    }
                  ></use>
                </svg>
                <svg>
                  <use xlinkHref={`#icon-${item.icon}`}></use>
                </svg>
                {item.name.includes("Eco-Friendly") && (
                  <svg
                    className="icon icon-vtt-eco"
                    style={{
                      maxWidth: "15px",
                      maxHeight: "15px"
                    }}>
                    <use xlinkHref="#icon-vtt-eco"></use>
                  </svg>
                )}
                <span>{item.name}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
