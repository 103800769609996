import React from "react";
import PropertyLoader from "./property/loader";
import FilterLoader from "./filters/loader";
import MapLoader from "./map-loader";

const LayoutLoader = (props) => {
  return (
    <>
      <div id="content" className="site-content site-content-loading">
        <div className="loading-message-bar">
          <span>{globalSettings.inter_text || 'Hang tight! We\'re searching for the best rates and availability for you.'}</span>
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
        <main className="main-content" style={{ paddingTop: 20 }}>
          <FilterLoader />
          <PropertyLoader />
          <PropertyLoader />
          <PropertyLoader />
        </main>
        <aside className="sidebar map">
          <MapLoader style={{width: '80%', height: '80%', position: 'absolute', top: 0, left: 0 }} />
        </aside>
      </div>
    </>
  );
};

export default LayoutLoader;
