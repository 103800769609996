import React, {useState, useCallback, useMemo} from "react";
import { createRoot } from "react-dom/client";
import SRPView from "./view";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { PropertiesProvider, useProperties } from "./context/properties.context";
import LayoutLoader from "./components/layout-loader";
import Modal from "./components/modal/index";
import Discounts from "./components/discounts";
import { useLoading, LoadingProvider } from "./context/loading.context";
import "./global.css";
import "./datepicker.css";

const queryClient = new QueryClient();

function SearchResultsPage() {
  const { properties, discounts, urlFilters } = useProperties();
  const { isLoading } = useLoading();
  const [showModal, setShowModal] = useState(true);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (isLoading) {
    document.getElementsByTagName("body")[0].classList.add("is-loading");
    return <LayoutLoader />;
  } else {
    document.getElementsByTagName("body")[0].classList.remove("is-loading");
  }

  return (
    <>
      <SRPView
        page={1}
        showModal={handleShowModal}
        properties={properties}
        discounts={discounts || []}
      />
      {(discounts?.length > 0 && showModal && !urlFilters?.PromoCode) && (
        <Modal onClose={handleCloseModal}>
          <Discounts onClose={handleCloseModal} />
        </Modal>
      )}
    </>
  );
}

const App = () => {
  return (
    <LoadingProvider>
      <QueryClientProvider client={queryClient}>
        <PropertiesProvider>
          <SearchResultsPage />
        </PropertiesProvider>
      </QueryClientProvider>
    </LoadingProvider>
  );
};

// Mount the React app on the specified container
createRoot(document.getElementById("srp-root")).render(<App />);
