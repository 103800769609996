import { useEffect, useRef } from 'react';

const useSwipe = (onSwipe) => {
  const startY = useRef(null);

  useEffect(() => {
    const handleTouchStart = (event) => {
      startY.current = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
      if (startY.current !== null) {
        const endY = event.touches[0].clientY;
        const deltaY = endY - startY.current;

        if (Math.abs(deltaY) > 50) {
          const direction = deltaY > 0 ? 'down' : 'up';
          onSwipe && onSwipe({ direction });
          // Reset startY to avoid continuous swipes during a single touch event
          startY.current = null;
        }
      }
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [onSwipe]);

  return {};
};

export default useSwipe;
