export function getHeadersHeight() {
  const headers = [
    document.querySelector(".site-header"),
    [...document.querySelectorAll(".subnav")],
  ].flat();

  return headers.reduce(function (acc, cur) {
    return acc + (cur?.clientHeight || 0);
  }, 0);
}
