import React from "react";
import ContentLoader from "react-content-loader";

const MapLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={700}
      height={500}
      viewBox="0 0 700 500"
      backgroundColor="#c8c8c8"
      foregroundColor="#ecebeb"
      {...props}
      style={{width: '80%', height: '80%'}}
    >
      <rect x="40" y="20" rx="4" ry="4" width="500" height="200" style={{width: '80%', height: '80%'}}></rect>
    </ContentLoader>
  );
};

export default MapLoader;
