import React, { useState, useEffect } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useProperties } from "../../context/properties.context";

const PropertyTypes = ({ propertyTypes, applyFilters, clear }) => {
  const { urlFilters } = useProperties();
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const [propertyTypesSelected, setPropertyTypesSelected] = useState([]);

  useEffect(() => {
    if (clear) {
      setPropertyTypesSelected([]);

      return
    }

    if (Object.keys(urlFilters).length && urlFilters.property_types) {
      setPropertyTypesSelected(urlFilters.property_types);
    }
  }, [clear]);

  const toggleListVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handlePropertyTypeClick = (item) => {
    let property_types;
    let id = parseInt(item.property_type_id);
    if (propertyTypesSelected.includes(id)) {
      property_types = propertyTypesSelected.filter((f) => f !== id);
    } else {
      property_types = [...propertyTypesSelected, id];
    }
    setPropertyTypesSelected(property_types);
    applyFilters({ property_types });
  };

  const calculateIconClass = (item) => {
    return propertyTypesSelected.includes(parseInt(item.property_type_id))
      ? "icon active"
      : "icon";
  };

  const filterTypesClass = `filter_types ${isVisible ? "active" : ""}`;

  return (
    <div ref={ref} className={filterTypesClass}>
      <h3
        className="filter_heading"
        data-group="filters"
        onClick={toggleListVisibility}
      >
        Property Types
        {propertyTypesSelected.length > 0 && (
          <span className="filter-badge">{propertyTypesSelected.length}</span>
        )}
        <span className="drop-indicator">
          <svg className={`icon icon-chevron-down ${isVisible ? "open" : ""}`}>
            <use xlinkHref="#icon-chevron-down"></use>
          </svg>
        </span>
      </h3>
      <div className="filter_list">
        <ul>
          {propertyTypes &&
            propertyTypes.map((item, idx) => {
              return (
                <li
                  key={`property-type-${idx}`}
                  data-group="filter-items"
                  className="property"
                  data-id={item.id}
                  data-family="parent"
                  onClick={() => handlePropertyTypeClick(item)}
                >
                  <svg
                    className={calculateIconClass(item)}
                    data-group="check-mark"
                  >
                    <use
                      xlinkHref={
                        propertyTypesSelected.includes(
                          parseInt(item.property_type_id)
                        )
                          ? "#icon-check-active"
                          : "#icon-check-empty"
                      }
                    ></use>
                  </svg>
                  <span>{item.name}</span>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default PropertyTypes;
