export default function sortProperties(properties, sortType) {
  if (sortType === "price") {
    properties.sort((a, b) => {
      const subtotalA = a.rate?.low?.total?.subtotal;
      const subtotalB = b.rate?.low?.total?.subtotal;

      if (subtotalA !== undefined && subtotalB !== undefined) {
        return subtotalA - subtotalB;
      } else if (subtotalA !== undefined) {
        return -1;
      } else if (subtotalB !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
  } else if (sortType === "name") {
    properties.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  } else if (sortType === "rating") {
    properties.sort((a, b) => {
      const ratingA = a.property_rating?.stars?.value;
      const ratingB = b.property_rating?.stars?.value;

      if (ratingA !== undefined && ratingB !== undefined) {
        return ratingB - ratingA;
      } else if (ratingA !== undefined) {
        return -1;
      } else if (ratingB !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return properties;
}
