import React from "react";

export default function Stars({ stars }) {
  return (
    <div className="star-rating">
      <div className="stars" data-stars={stars}>
        <svg
          height="13"
          width="6"
          className="star rating left-star"
          data-rating=".5"
        >
          <polygon points="4.6 4.16 0 4.83 3.33 8.07 2.54 12.65 6.66 10.49 6.66 0 4.6 4.16"></polygon>
        </svg>
        <svg height="13" width="6" className="star right-star" data-rating="1">
          <polygon points="2.06 4.16 6.66 4.83 3.33 8.07 4.12 12.65 0 10.49 0 0 2.06 4.16"></polygon>
        </svg>
        <svg
          height="13"
          width="6"
          className="star rating left-star"
          data-rating="1.5"
        >
          <polygon points="4.6 4.16 0 4.83 3.33 8.07 2.54 12.65 6.66 10.49 6.66 0 4.6 4.16"></polygon>
        </svg>
        <svg height="13" width="6" className="star right-star" data-rating="2">
          <polygon points="2.06 4.16 6.66 4.83 3.33 8.07 4.12 12.65 0 10.49 0 0 2.06 4.16"></polygon>
        </svg>
        <svg
          height="13"
          width="6"
          className="star rating left-star"
          data-rating="2.5"
        >
          <polygon points="4.6 4.16 0 4.83 3.33 8.07 2.54 12.65 6.66 10.49 6.66 0 4.6 4.16"></polygon>
        </svg>
        <svg height="13" width="6" className="star right-star" data-rating="3">
          <polygon points="2.06 4.16 6.66 4.83 3.33 8.07 4.12 12.65 0 10.49 0 0 2.06 4.16"></polygon>
        </svg>
        <svg
          height="13"
          width="6"
          className="star rating left-star"
          data-rating="3.5"
        >
          <polygon points="4.6 4.16 0 4.83 3.33 8.07 2.54 12.65 6.66 10.49 6.66 0 4.6 4.16"></polygon>
        </svg>
        <svg height="13" width="6" className="star right-star" data-rating="4">
          <polygon points="2.06 4.16 6.66 4.83 3.33 8.07 4.12 12.65 0 10.49 0 0 2.06 4.16"></polygon>
        </svg>
        <svg
          height="13"
          width="6"
          className="star rating left-star"
          data-rating="4.5"
        >
          <polygon points="4.6 4.16 0 4.83 3.33 8.07 2.54 12.65 6.66 10.49 6.66 0 4.6 4.16"></polygon>
        </svg>
        <svg height="13" width="6" className="star right-star" data-rating="5">
          <polygon points="2.06 4.16 6.66 4.83 3.33 8.07 4.12 12.65 0 10.49 0 0 2.06 4.16"></polygon>
        </svg>
      </div>
    </div>
  );
}
