import axios from "axios";

const BASE_URL = globalSettings.host;
const CURRENCY = globalSettings.currency ?? "USD";
const session = JSON.parse(_session);
const affiliate = session?.affiliate ?? "";
const DIRECT = true;

function getStorageProperties(params) {
  const {
    locale = "en_US",
    currency = CURRENCY,
    units = session?.rooms ? session?.rooms : [{ adults: 2, children: 0 }],
    page = 1,
    checkin,
    checkout,
    referral = _affiliate ?? "",
    orderBy = _orderBy ?? "",
    propertyType,
    features,
    promoCode,
  } = params;

  let filters =
    propertyType && propertyType.toString() !== "0"
      ? `&property_types=${propertyType}`
      : "";

  filters += features ? `&features=${propertyType}` : "";

  let adultsChildren = units
    .map(
      (unit, index) =>
        `&rooms[${index}][adults]=${unit.adults}&rooms[${index}][children]=${unit.children}`
    )
    .join("");
  let sort = orderBy ? `&sort=${orderBy}` : "";

  const fullUrl = DIRECT ? `${BASE_URL}/publisher/v3.0/properties/available-properties.json` :
    `/properties-availability/properties`;

  const urlParams = `?key=${globalSettings.key}&referral=${referral}&destination_id=${destination.id}&locale=${locale}&currency=${currency}&checkin=${checkin}&checkout=${checkout}${adultsChildren}${sort}&page=${page}${filters}${promoCode ? `&discount_code=${promoCode}` : ''}&storage=true`;

  return axios.get(
    `${fullUrl}${urlParams}`,
    {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function getFirstProperties(params) {
  const session = JSON.parse(_session);
  const {
    locale = "en_US",
    currency = CURRENCY,
    units = session?.rooms ? session?.rooms : [{adults: 2, children: 0}],
    page = 1,
    referral = _affiliate ?? "",
    orderBy = _orderBy ?? "",
    checkin,
    checkout,
    propertyType,
    features,
    amenities,
    ratings,
    promoCode,
  } = params;

  let adultsChildren = units
    .map(
      (unit, index) =>
        `&rooms[${index}][adults]=${unit.adults}&rooms[${index}][children]=${unit.children}`
    )
    .join("");
  let sort = orderBy ? `&sort=${orderBy}` : "";

  let filters =
    propertyType && propertyType.toString() !== "0"
      ? `&property_types=${propertyType}`
      : "";

  filters += features ? `&features=${features}` : "";
  filters += amenities ? `&amenities=${amenities}` : "";
  filters += ratings ? `&ratings=${ratings}` : "";

  const fullUrl = DIRECT ? `${BASE_URL}/publisher/v3.0/properties/available-properties.json` :
    `/properties-availability/properties`;

  const urlParams = `?key=${globalSettings.key}&referral=${referral}&destination_id=${destination.id}&locale=${locale}&currency=${currency}&checkin=${checkin}&checkout=${checkout}${adultsChildren}${sort}&page=${page}${filters}${promoCode ? `&discount_code=${promoCode}` : ''}`;

  return axios.get(
    `${fullUrl}${urlParams}`,
    {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function getEanParityAvailable(params) {
  const session = JSON.parse(_session);

  const {
    locale = "en_US",
    currency = CURRENCY,
    units = session?.rooms ? session?.rooms : [{adults: 2, children: 0}],
    checkin,
    checkout,
    referral = _affiliate ?? "",
    promoCode
  } = params;

  let adultsChildren = units
    .map(
      (unit, index) =>
        `&rooms[${index}][adults]=${unit.adults}&rooms[${index}][children]=${unit.children}`
    )
    .join("");

  const fullUrl = DIRECT ? `${BASE_URL}/publisher/v3.0/ean-parity/save-available.json` :
    `/properties-availability/ean-parity`;

  const urlParams = `?key=${globalSettings.key}&referral=${referral}&destination_id=${destination.id}&locale=${locale}&currency=${currency}&checkin=${checkin}&checkout=${checkout}${adultsChildren}${promoCode ? `&discount_code=${promoCode}` : ''}`;

  return axios.get(
    `${fullUrl}${urlParams}`,
    {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function getDiscountsAvailable() {
  return axios.get(
    `/discounts/all`,
    {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function setSessionDates(checkin, checkout, units) {
  if (units) {
    let adultsChildren = units
      .map(
        (unit, index) =>
          `&rooms[${index}][adults]=${unit.adults}&rooms[${index}][children]=${unit.children}`
      )
      .join("");

    axios.get(
      `/set-session?checkin=${checkin}&checkout=${checkout}&rooms=${adultsChildren}`,
      {
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  return true;
}

function getFilters() {
  return axios.get(
    `/properties-availability/get-filters`,
    {
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export {
  getStorageProperties,
  getFirstProperties,
  getEanParityAvailable,
  setSessionDates,
  getDiscountsAvailable,
  getFilters
};
