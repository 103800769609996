/* global google */
import React, { useRef, useEffect, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { createRoot } from "react-dom/client";
import PropertyGallery from "../components/property/gallery";
import Stars from "../components/property/stars";

const MapLoader = ({ hasError }) => (
  <div>{hasError ? "Error :(" : "Loading..."}</div>
);

function MapComponent({ mapConfig, children, locations }) {
  const [map, setMap] = React.useState();
  const ref = useRef();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {...mapConfig,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
      }));
    }
  }, [ref, map]);

  return (
    <>
      <div ref={ref} id="map" />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
}

function Marker({ map, position, children, onClick }) {
  const rootRef = useRef();
  const markerRef = useRef();

  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement("div");
      rootRef.current = createRoot(container);

      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        position,
        content: container,
        zIndex: 100000,
        style: {
          pointerEvents: 'all'
        }
      });
    }

    return () => (markerRef.current.map = null);
  }, []);

  useEffect(() => {
    rootRef.current.render(children);
    markerRef.current.position = position;
    markerRef.current.map = map;
    const listener = markerRef.current.addListener("click", (e) => {
      markerRef.current.zIndex = 999999;
      onClick(e, markerRef.current);
    });
    return () => listener.remove();
  }, [map, position, children]);
}

function Pin({ map, position, children, onClick }) {
  const rootRef = useRef();
  const markerRef = useRef();

  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement("div");
      rootRef.current = createRoot(container);

      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        position,
        content: container,
        zIndex: 10000,
        style: {
          pointerEvents: 'all'
        }
      });
    }

    return () => (markerRef.current.map = null);
  }, []);

  useEffect(() => {
    rootRef.current.render(children);
    markerRef.current.position = position;
    markerRef.current.map = map;
    const listener = markerRef.current.addListener("click", (e) => {
      markerRef.current.zIndex = 100000;
      onClick(e, markerRef.current);
    });
    return () => listener.remove();
  }, [map, position, children]);
}

export default function SimpleMap({ locations }) {
  // console.log("dest", globalSettings);
  const [highlight, setHighlight] = useState(null);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mapID = globalSettings.google_map_id;
  const mapsApiKey = urlParams.get("api-key") || googleMapsAPIKey;

  const defaultProps = {
    center: {
      lat: globalSettings.map.latitude,
      lng: globalSettings.map.longitude,
    },
    zoom: parseInt(globalSettings.map.zoom) == 0 ? 10 : globalSettings.map.zoom,
    scrollwheel: false,
    mapTypeControl: false,
    streetViewControl: false,
    gestureHandling: "cooperative",
    zoomControl: true,
    scaleControl: true,
    ...(mapID) && {mapId: mapID}
  };

  const render = (status) => {
    if (status === Status.FAILURE) return <MapLoader hasError />;
    if (status === Status.LOADING) return <MapLoader />;
    return (
      <MapComponent mapConfig={defaultProps} locations={locations}>
        {defaultProps.mapId && locations?.map((pin, idx) => (
          <Marker
            key={`marker-${idx}`}
            position={pin.position}
            onClick={(e, marker) => {
              const { target } = e.domEvent;

              if (target.href) {
                window.location.href = target.href;

                return setHighlight(null);  
              }

              if (target.classList.contains('highlight-prop__close')) {
                marker.zIndex = 100000;
                return setHighlight(null);  
              }

              if (target.classList.contains('next') || target.classList.contains('prev') || target.classList.contains('icon')) {
                return null;
              }

              return setHighlight((prev) => !prev ? pin.id : null);
            }}
          >
            <div
              className={`marker`}
              title={pin.name}
            >
              {highlight === pin.id && (
                <div className="highlight-prop">
                  <div className="highlight-prop__images">
                    <PropertyGallery
                      name={pin.name}
                      permalink={pin.permalink.property}
                      property_image={pin.property_image}
                      property_description={pin.property_description}
                    />
                  </div>
                  <div className="highlight-prop__content">
                    <h2><a href={`/${pin.permalink.property}`} target="_blank">{pin.name}</a></h2>
                    <h3>{`${
                      pin.label !== "NaN" ? `$${pin.label}` : "Sold Out"
                    }`}</h3>
                    <div className="highlight-prop__rates">
                      <div className="tripadvisor">
                        {globalSettings?.display_tripadvisor == 1 &&
                          pin.property_rating?.trip_advisor && (
                            <img
                              src={
                                pin.property_rating?.trip_advisor.meta_data
                                  .rating_image_url
                              }
                            />
                          )}
                      </div>

                      {pin.property_rating?.stars.value != 0 && (
                        <Stars stars={pin.property_rating?.stars.value} />
                      )}
                    </div>
                  </div>
                  <span className="highlight-prop__close">x</span>
                </div>
              )}

              <span title={pin.name} className="hotel-pin" style={{
                background: "#fff",
                color: "#000",
                padding: "3px 8px",
                display: "inline-block",
                fontWeight: "bold",
                fontSize: "15px",
                borderRadius: "3px",
              }}>{`${
                pin.label !== "NaN" ? `$${pin.label}` : "Sold Out"
              }`}</span>
              
            </div>
          </Marker>
        ))}
        {[...globalSettings.map.pins]?.map((pin, idx) => {
          return (
            <Pin
              key={`pin-${idx}`}
              position={{
                lat: pin.latitude * 1,
                lng: pin.longitude * 1,
              }}
              onClick={(e, marker) => {
                const { target } = e.domEvent;

                if (target.classList.contains('highlight-prop__close')) {
                  marker.zIndex = 10000;
                  return setHighlight(null);  
                }

                if (target.classList.contains('next') || target.classList.contains('prev') || target.classList.contains('icon')) {
                  return null;
                }

                return setHighlight((prev) => !prev ? pin.name : null);
              }}
            >
              <div
                className={`pin`}
                title={pin.name}
              >
                {highlight === pin.name && (
                  <div className="highlight-prop">
                    <div className="highlight-prop__images">
                      <img
                        src={pin.photo || 'https://placehold.jp/250x150.png'}
                        className="highlight-prop__img"
                      />
                    </div>
                    <div className="highlight-prop__content">
                      <h2>{pin.name}</h2>
                      <p className="highlight-prop__desc">
                        {pin.description}
                      </p>
                    </div>
                    <span className="highlight-prop__close">x</span>
                  </div>
                )}
                <div title={pin.name} className="poi-pin" style={{
                  display: "inline-block",
                  minWidth: '32px',
                  width: pin.svg_size ?? 'auto',
                  height: pin.svg_size ?? 32 + 'px',
                }}>
                  <img src={pin.image} style={{
                    width: pin.svg_size ?? 'auto',
                    height: pin.svg_size ?? 32 + 'px',
                    maxHeight: '32px',
                    objectFit: pin.svg_size > 32 ? 'contain' : 'cover'
                  }} />
                </div>
              </div>
            </Pin>
          )
        })}
      </MapComponent>
    );
  };

  return (
    <Wrapper
      apiKey={mapsApiKey}
      render={render}
      version="beta"
      libraries={["marker"]}
    />
  );
}
///418ecbfdf96ec0f9