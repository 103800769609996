import React, { useState, useEffect } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useProperties } from "../../context/properties.context";

export default function Rating({ ratings, applyFilters, clear }) {
  const { urlFilters } = useProperties();
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const [ratingSelected, setRatingSelected] = useState([]);

  useEffect(() => {
    if (clear) {
      setRatingSelected([]);

      return
    }

    if (Object.keys(urlFilters).length && urlFilters.rating) {
      setRatingSelected(urlFilters.rating);
    }
  }, [clear]);

  const toggleListVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleRatingClick = (item) => {
    const newRagingSelected = new Set(ratingSelected);

    if (newRagingSelected.has(item.id)) {
      newRagingSelected.delete(item.id);
    } else {
      newRagingSelected.add(item.id);
    }

    const updatedRatingsSelected = Array.from(newRagingSelected);
    setRatingSelected(updatedRatingsSelected);

    applyFilters({ rating: updatedRatingsSelected });
  };

  const classDiv = `filter_ratings ${isVisible ? "active" : ""}`;

  const calculateIconClass = (item) => {
    return ratingSelected.includes(item.id) ? "icon active" : "icon";
  };

  return (
    <div ref={ref} className={classDiv}>
      <h3
        className="filter_heading"
        data-group="filters"
        onClick={toggleListVisibility}
      >
        Ratings
        {ratingSelected.length > 0 && (
          <span className="filter-badge">{ratingSelected.length}</span>
        )}{" "}
        <span className="drop-indicator">
          <svg className="icon icon-chevron-down">
            <use xlinkHref="#icon-chevron-down"></use>
          </svg>
        </span>
      </h3>

      <div className="filter_list">
        <ul>
          {ratings &&
            ratings.map((item, idx) => {
              return (
                <li
                  key={`stars-${item.id}`}
                  data-group="filter-items"
                  className="star"
                  onClick={() => handleRatingClick(item)}
                >
                  <svg
                    className={calculateIconClass(item)}
                    data-group="check-mark"
                  >
                    <use
                      xlinkHref={
                        ratingSelected.includes(item.id)
                          ? "#icon-check-active"
                          : "#icon-check-empty"
                      }
                    ></use>
                  </svg>
                  <span> {item.name}</span>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
