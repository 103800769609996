import React, { useState, useEffect } from "react";
import { useProperties } from "../context/properties.context";
import { Select, ConfigProvider } from "antd";

function Discounts({ onClose }) {
  const [showDetails, setShowDetails] = useState(true);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const { refetch, discounts } = useProperties();

  useEffect(() => {
    if (discounts.length === 1) {
      setSelectedDiscount(() => discounts[0].code);
    }
  }, [discounts]);

  const options = discounts.map((opt) => ({
    value: opt.code,
    label: opt.title,
  }));

  return (
    <div className="discounts-modal">
      <div className="discounts-modal-header">
        <div className="discounts-modal-img">
          <img src="/img/modal-hero.svg" />
        </div>
        <div className="discounts-modal-heading">
          <h1 className="discounts-modal-title">
            {discounts.length > 1
              ? "Your stay qualifies for a free gift:"
              : "Your stay qualifies for a"}
          </h1>
          {discounts.length === 1 ? (
            <h2 className="discounts-modal-item">{discounts[0].title}</h2>
          ) : (
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    colorPrimary: globalSettings.single_hotel_pin_color,
                    fontSize: 21,
                    optionFontSize: 14,
                    algorithm: true,
                    optionSelectedColor: globalSettings.single_hotel_pin_color,
                    optionSelectedFontWeight: 600,
                    colorTextPlaceholder: globalSettings.single_hotel_pin_color,
                    colorIcon: globalSettings.single_hotel_pin_color,
                    zIndexPopup: 200000
                  },
                },
              }}
            >
              <Select
                onChange={(value) => {setSelectedDiscount(value); setShowDetails(true);}}
                placeholder="Choose your gift"
                options={options}
                style={{maxWidth: '400px', fontWeight: 600}}
                defaultValue={selectedDiscount || undefined}
              />
            </ConfigProvider>
          )}
          {selectedDiscount && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowDetails(!showDetails);
              }}
            >
              {!showDetails ? 'Show' : 'Hide'} Details{" "}
              {!showDetails ? (
                <span className="discounts-caret -down"></span>
              ) : (
                <span className="discounts-caret -up"></span>
              )}
            </a>
          )}
        </div>
      </div>
      <div className="discounts-modal-body">
        {selectedDiscount && showDetails && (
          <div className="discounts-modal-details">
            {discounts.length === 1
              ? discounts[0].description
              : discounts.find((d) => d.code === selectedDiscount).description}
          </div>
        )}
      </div>
      <div className="discounts-modal-actions">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          No Thanks
        </button>
        <button
          type="button"
          disabled={!selectedDiscount}
          onClick={(e) => {
            e.preventDefault();
            onClose();
            refetch({ promoCode: selectedDiscount });
          }}
        >
          Check Availability
        </button>
      </div>
    </div>
  );
}

export default Discounts;
