import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import Occupancy from "./occupancy";
import { setSessionDates } from "../api";
import useIsMobile from "../hooks/useIsMobile";
import useSwipe from "../hooks/useSwipe"
import { useProperties } from "../context/properties.context";
import { getHeadersHeight } from "../utils/getHeaderHeight";

const CustomArrowIcon = () => "→";

export default function SearchBar({ handleApplyDatesOccupancy }) {
  const { $session } = useProperties();
  const [checkin, setCheckin] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const [units, setUnits] = useState(
    $session?.rooms || [{ adults: 2, children: 0 }]
  );
  const [focusedInput, setFocusedInput] = useState(null);
  const isMobile = useIsMobile();
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);
  const datePickerRef = useRef(null);

  const UpdateButtonMobileRef = useRef(null);

  const originalDates = useRef({
    startDate: moment($session.checkin),
    endDate: moment($session.checkout)
  })

  useEffect(() => {
    const chatBubble = document.querySelector('#drift-frame-controller');
    if (chatBubble) {
      focusedInput ? chatBubble.classList.add('hide-chat') : chatBubble.classList.remove('hide-chat');
    }
  }, [focusedInput]);

  useEffect(() => {
    if ($session) {
      setCheckin(moment($session.checkin));
      setCheckout(moment($session.checkout));
    }
  }, []);

  useLayoutEffect(() => {
    const { container } = datePickerRef.current;
    const datePickerInputs = container.querySelectorAll('.DateInput_input');
    const datePickerInputsArray = Array.from(datePickerInputs);

    datePickerInputsArray.forEach((input, index) => {
      const label = document.createElement('label');
      label.classList.add('date-picker-label');
      label.innerText = input.getAttribute('aria-label');
      input.parentNode.appendChild(label);
    });

    if (isMobile) {
      const style = document.createElement("style");
      document.head.appendChild(style);
      style.sheet.insertRule(`.DateRangePicker_picker { padding-top: ${getHeadersHeight() + 4}px !important; }`, 0);
      style.sheet.insertRule(`.DayPickerNavigation_nextButton__verticalDefault { top: calc(50% + ${(getHeadersHeight() / 3)}px);  }`, 0);
      style.sheet.insertRule(`.DayPickerNavigation_prevButton__verticalDefault { top: calc(80px + ${(getHeadersHeight())}px);  }`, 0);
    }
  },[])

  function compareUnits(array1, array2) {
    if (array1.length !== array2.length) return false;

    for (let i = 0; i < array1.length; i++) {
      const obj1 = array1[i];
      const obj2 = array2[i];

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;
      for (const key of keys1) {
        if (obj1[key] !== obj2[key]) return false;
      }
    }
    return true;
  }

  const updateOcuppancy = (paramUnits, hasToUpdate) => {
    if (hasToUpdate) {
      setUpdateButtonDisabled(false);
      setUnits(paramUnits);
      filterDatesOccupancy(paramUnits);
    }
  };

  const filterDatesOccupancy = (paramUnits) => {
    setSessionDates(
      checkin.format("YYYY-MM-DD"),
      checkout.format("YYYY-MM-DD"),
      paramUnits || units
    );

    handleApplyDatesOccupancy({
      checkin: checkin.format("YYYY-MM-DD"),
      checkout: checkout.format("YYYY-MM-DD"),
      units: paramUnits || units,
    });
  };

  const updateCheckinCheckout = (startDate, endDate) => {
    if (
      (startDate &&
        checkin &&
        startDate.format("YYYY-MM-DD") !== checkin.format("YYYY-MM-DD")) ||
      (endDate &&
        checkout &&
        endDate.format("YYYY-MM-DD") !== checkout.format("YYYY-MM-DD"))
    ) {
      setUpdateButtonDisabled(false);
    }

    setCheckin(startDate);
    setCheckout(endDate);
  };

  const handleConfirm = () => {
    originalDates.current = {startDate: checkin, endDate: checkout};
    setFocusedInput(null);
    filterDatesOccupancy();
  };

  const handleCancel = () => {
    const { startDate, endDate } = originalDates.current;
    const {checkin, checkout} = $session;

    console.log({startDate, endDate, checkin, checkout})

    updateCheckinCheckout(startDate, endDate);

    if (startDate.format("YYYY-MM-DD") === checkin && endDate.format("YYYY-MM-DD") === checkout) {
      setUpdateButtonDisabled(true);
    }

    setFocusedInput(null);
  };

  const enabledStyle = {
    color: '#fff',
    background: globalSettings.single_hotel_pin_color
  }

  const handleSwipe = ({direction}) => {
    if(focusedInput) {
      if (direction === 'up') {
        document.querySelector('.DayPickerNavigation_nextButton__verticalDefault').click();
      } else if (direction === 'down') {
        document.querySelector('.DayPickerNavigation_prevButton__verticalDefault').click();
      }
    }
  };

  const swipeBind = useSwipe(handleSwipe);

  return (
    <>
      <div id="main-search" className="main-search">
        <div className="form-container">
          <div className="main-search_dates"  {...swipeBind}  >
            <DateRangePicker
              startDate={checkin}
              startDateId="stardDateId"
              startDateAriaLabel="Check-In"
              endDateAriaLabel="Check-Out"
              endDate={checkout}
              endDateId="endDateId"
              onDatesChange={({ startDate, endDate }) => {
                updateCheckinCheckout(startDate, endDate);
              }}
              readOnly={isMobile}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => {
                if (!focusedInput) {
                  document.querySelector('body').style.overflowY = null;
                  return
                }

                document.querySelector('body').style.overflowY = 'hidden';
                setFocusedInput(focusedInput)
              }}
              noBorder={true}
              minDate={moment().day(0)}
              customArrowIcon={<CustomArrowIcon />}
              monthFormat="MMM yyyy"
              displayFormat="MMM DD, YYYY"
              orientation={isMobile ? "vertical" : "horizontal"}
              ref={datePickerRef}
            />
            
            {focusedInput && (
              <>
                <button className="daterange-actions --cancel" onClick={handleCancel}>Cancel</button>
                <button className="daterange-actions --confirm" onClick={handleConfirm}>Confirm Dates</button>
              </>
            )}
          </div>

          <Occupancy updateOcuppancy={updateOcuppancy} compareUnits={compareUnits} sbUnits={units} />

          {/* <div className="main-search_submit">
            <button
              disabled={updateButtonDisabled}
              className="cta update"
              onClick={filterDatesOccupancy}
              style={!updateButtonDisabled ? enabledStyle : null}
            >
              Update
            </button>

            <input
              type="hidden"
              id="urlPromoCode"
              name="urlPromoCode"
              value=""
            />
          </div>

          <div className="main-search_mobile-filters">
            <button
              className="cta update" 
              disabled={updateButtonDisabled}
              onClick={filterDatesOccupancy}
              style={!updateButtonDisabled ? enabledStyle : null}
              ref={UpdateButtonMobileRef}
            >
              Update
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}
