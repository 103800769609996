import React, { useState, useEffect } from "react";
import Stars from "./property/stars";
import AmenitiesCard from "./property/amenities-card";
import PriceCard from "./property/price-card";
import PropertyGallery from "./property/gallery";
import { useProperties } from "../context/properties.context";

export default function PropertyCard({ property, filters }) {
  const {
    id,
    settings,
    property_description,
    permalink,
    name,
    feature,
    property_rating,
    is_available,
    property_type,
    property_image,
    rate
  } = property;

  const { $session: session } = useProperties();

  const affiliateUrl = _affiliate ? "affiliate=" + _affiliate : "";
  const promoCode = property.rate.low.discount ? `PromoCode=${rate.low.discount.code}` : '';

  function propertyName(id) {
    return id + "Property name";
  }

  function getAreaChecked(id) {
    if (!session) return "0";

    if (session.hasOwnProperty("areaId")) {
      let areaList = session.areaId;
      if (Array.isArray(areaList)) {
        if (areaList.includes(id)) {
          return "1";
        }
      } else if (Number.isInteger(areaList)) {
        if (id == areaList) {
          return "1";
        }
      }
    } else {
      //console.log("No areaId found or wrong format in session.Booking!");
    }

    return "0";
  }

  function getAreas() {
    switch (globalSettings?.destination_type) {
      case "multiple":
        let destinationId = parseInt(
          configJs.getAttribute("data-destination-id"),
          10
        );

        let destinations = globalSettings?.allowed_filters.destinations.filter(
          (destination) => {
            if (destination.id == destinationId) {
              destination["checked"] = getAreaChecked(destination.id);
              return destination;
            }
          }
        );

        for (let i = 0; i < destinations?.length; i++) {
          if (destinations[i]["id"] > 0) {
            destinations[i]["checked"] = getAreaChecked(destinations[i]["id"]);
          } else {
            destinations[i]["checked"] = "0";
          }
        }

        if (destinations?.length == 1) {
          for (let i = 0; i < destinations[0].children?.length; i++) {
            if (destinations[0].children[i].id > 0) {
              destinations[0].children[i]["checked"] = getAreaChecked(
                destinations[0].children[i].id
              );
            } else {
              destinations[0].children[i]["checked"] = "0";
            }
          }
          return destinations[0].children;
        }
        break;
      default:
        if (globalSettings?.allowed_filters?.destinations?.length === 1) {
          globalSettings.allowed_filters.destinations[0]["checked"] =
            getAreaChecked(globalSettings?.allowed_filters.destinations[0].id);
          for (
            let i = 0;
            i <
            globalSettings?.allowed_filters.destinations[0].children?.length;
            i++
          ) {
            globalSettings.allowed_filters.destinations[0].children[i][
              "checked"
            ] = getAreaChecked(
              globalSettings?.allowed_filters.destinations[0].children[i].id
            );
          }
          return globalSettings?.allowed_filters.destinations[0].children;
        } else {
          for (
            let j = 0;
            j < globalSettings?.allowed_filters.destinations?.length;
            j++
          ) {
            globalSettings.allowed_filters.destinations[j]["checked"] =
              getAreaChecked(
                globalSettings?.allowed_filters.destinations[j].id
              );
            for (
              let i = 0;
              i <
              globalSettings?.allowed_filters.destinations[j].children?.length;
              i++
            ) {
              globalSettings.allowed_filters.destinations[j].children[i][
                "checked"
              ] = getAreaChecked(
                globalSettings?.allowed_filters.destinations[j].children[i].id
              );
            }
          }
        }

        return globalSettings?.allowed_filters.destinations;
        break;
    }
  }

  function propertyArea(id) {
    if (property.destination == undefined || property.destination == null) {
      return "";
    }

    let areas = getAreas();

    var resultString = "";

    if (
      property.destination.areas !== undefined &&
      property.destination.areas !== null
    ) {
      for (var j = 0; j < property.destination.areas?.length; j++) {
        for (var k = 0; k < areas?.length; k++) {
          if (areas[k].children !== undefined && areas[k].children !== null) {
            for (var i = 0; i < areas[k].children?.length; i++) {
              if (
                areas[k].children[i].name.trim() ==
                  property.destination.areas[j].name.trim() ||
                areas[k].children[i].id == property.destination.areas[j].id
              ) {
                resultString =
                  areas[k].name + " - " + areas[k].children[i].name;
              }
            }
          } else {
            if (
              areas[k].name.trim() ==
                property.destination.areas[j].name.trim() ||
              areas[k].id == property.destination.areas[j].id
            ) {
              resultString = areas[k].name;
            }
          }
        }
      }
    }

    if (resultString != "") {
      return resultString;
    } else if (
      property.destination.areas != null &&
      property.destination.areas != undefined
    ) {
      let areaStr = "";
      for (var i = 0; i < property.destination.areas?.length; i++) {
        if (property.destination.areas[i].parent != null) {
          areaStr += property.destination.areas[i].parent + " - ";
        }
        areaStr += property.destination.areas[i].name;
        if (i < property.destination.areas?.length - 1) {
          areaStr += ", ";
        }
      }
      return areaStr;
    }
    return property.destination.name;
  }

  function getPropertyLink(property) {
    if (globalSettings?.destination_type == "multiple") {
      return `${property.permalink.geo}${affiliateUrl || promoCode ? '?' : ''}${affiliateUrl}${affiliateUrl ? '&' : ''}${promoCode}`;
    }
    return `${property.permalink.property}${affiliateUrl || promoCode ? '?' : ''}${affiliateUrl}${affiliateUrl ? '&' : ''}${promoCode}`;
  }

  function getAmenitiesMobile() {
    const amenityList = globalSettings?.feature_filters?.map((item, idx) => {
      return (
        <li key={`key-${idx}`} data-ref={item.id}>
          <svg
            className={hasFeature(property, item)}
            s
            title={item.name}
            rel="amen-tooltip"
          >
            <use xlinkHref={`#icon-${item.icon}`}></use>
          </svg>
        </li>
      );
    });
  }

  return (
    <li className="property-card">
      <PropertyGallery
        name={propertyName(id)}
        permalink={getPropertyLink(property)}
        property_image={property_image}
        property_description={property_description}
      />

      <div className="property-card_details">
        <div className="property-card_main">
          <div style={{ paddingBottom: "10px" }}>
            <h2 className="property-card_title">
              <a
                href={`/${getPropertyLink(property)}`}
                target="_blank"
                data-prop_name={propertyName(id)}
                data-provider={property?.inventory_connection}
                className="property_link"
              >
                {name}
              </a>
              {feature == "A" && (
                <span style={{marginLeft: '5px'}}>
                  <svg
                    className="icon icon-scallop-check"
                    rel="tooltip"
                    title="Featured Lodging"
                  >
                    <use xlinkHref="#icon-scallop-check"></use>
                  </svg>
                </span>
              )}
            </h2>
            <h3 className="property-card_title_meta">
              {`${propertyArea(property)} | ${property_type?.name}`}
            </h3>

            <div className="property-card_rating">
              <div className="tripadvisor">
                {globalSettings?.display_tripadvisor == 1 &&
                  property_rating?.trip_advisor && (
                    <img
                      src={
                        property_rating?.trip_advisor.meta_data.rating_image_url
                      }
                    />
                  )}
              </div>

              {property_rating?.stars.value != 0 && (
                <Stars stars={property_rating?.stars.value} />
              )}
            </div>
          </div>

          <PriceCard
            property={property}
            globalSettings={globalSettings}
            session={session}
          />
        </div>
        <div className="property-card_tabs">
          <AmenitiesCard property={property} globalSettings={globalSettings} />

          <div className="property-card_book">
            <a
              className="btn property_link"
              href={`/${getPropertyLink(property)}`}
              target="_blank"
              data-prop_name="propertyName(property.id)"
            >
              View & Book
            </a>
          </div>
        </div>
      </div>
    </li>
  );
}
