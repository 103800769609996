import React from 'react';
import { createPortal } from "react-dom";
import './styles.css';

const Modal = ({ onClose, children }) => (
  createPortal(
    <div className="srp-modal">
      <div className='srp-modal-backdrop' onClick={(e) => {e.preventDefault(); onClose();}} />
      <div className="srp-modal-content">
        <button className="srp-close-button" onClick={(e) => {e.preventDefault(); onClose();}}>
          &times;
        </button>
        {children}
      </div>
    </div>,
    document.getElementById('modal-root')
  )
);

export default Modal;
