import React, {
  useState,
  useRef,
  useEffect,
  createContext,
  useContext,
} from "react";
import { useQuery } from "@tanstack/react-query";
import { useLoading } from "./loading.context";
import {
  getStorageProperties,
  getFirstProperties,
  getEanParityAvailable,
  getDiscountsAvailable,
  getFilters,
} from "../api";

const PropertiesContext = createContext();

export const PropertiesProvider = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const $session = useRef(JSON.parse(_session));
  const [params, setParams] = useState({
    checkin: $session.current.checkin,
    checkout: $session.current.checkout,
    units: $session.current.rooms,
    promoCode: urlParams.get('PromoCode') === 'NotSelected' ? null : urlParams.get('PromoCode') || null,
  });
  const [properties, setProperties] = useState([]);
  const [urlFilters, setUrlFilters] = useState({});
  const [filters, setFilters] = useState({});
  const { setIsLoading } = useLoading();
  const [totalProperties, setTotalproperties] = useState(0);
  const allowPagination = useRef(globalSettings.allow_pagination);
  const showSoldOut = useRef(globalSettings.show_sold_out);

  const { data: $filters, isSuccess: $filtersLoaded } = useQuery({
    queryKey: ["filters"],
    queryFn: () => getFilters(),
    refetchOnWindowFocus: false,
  });

  const { data: $discounts, isSuccess: $discountsLoaded } = useQuery({
    queryKey: ["discounts", params],
    queryFn: () => getDiscountsAvailable(params),
    refetchOnWindowFocus: false,
  });

  const {
    data: $firstProperties,
    isFetching: $firstFetching,
    isSuccess: $firstLoaded,
  } = useQuery({
    queryKey: ["firstProps", params],
    queryFn: () => getFirstProperties(params),
    refetchOnWindowFocus: false,
  });

  const {
    data: $storageProperties,
    isFetching: $storageFetching,
    isSuccess: $storageLoaded,
  } = useQuery({
    queryKey: ["storageData", params],
    queryFn: () => getStorageProperties(params),
    refetchOnWindowFocus: false,
  });

  const {
    data: $eanProperties,
    isFetching: $parityFetching,
    isSuccess: $parityLoaded,
  } = useQuery({
    queryKey: ["eanProperties", params],
    queryFn: () => getEanParityAvailable(params),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const areas =
      globalSettings.allowed_filters.destinations.length === 1
        ? globalSettings.allowed_filters.destinations[0].children
        : globalSettings.allowed_filters.destinations;
    const filtersFromURL = {};
    const otherParams = ["areas", "propertyType", "PromoCode", "pagination"];

    if ($filtersLoaded) {
      setFilters({ ...$filters.data, areas });

      for (const [key, value] of urlParams.entries()) {
        if (
          Object.keys($filters.data).includes(key) ||
          otherParams.includes(key)
        ) {
          if (key === "PromoCode") {
            filtersFromURL[key] = value === 'NotSelected' ? null : value;
          } else {
            filtersFromURL[key === "propertyType" ? "property_types" : key] =
              key === "pagination"
                ? value
                : value?.split(",").map((v) => parseInt(v) || v);
          }
        }
      }

      setUrlFilters(() => filtersFromURL);
    }
  }, [$filtersLoaded]);

  useEffect(() => {
    // When storageData and eanProperties are ready, or firstProps is ready before the other two
    if (
      ($storageLoaded &&
        $parityLoaded &&
        !$storageFetching &&
        !$parityFetching) ||
      ($firstLoaded && !$firstFetching)
    ) {
      if (
        $firstLoaded &&
        !($storageLoaded && $parityLoaded) &&
        !properties.length &&
        !Object.keys(urlFilters).length
      ) {
        // If firstProps is loaded and full list is pending use firstProps data
        setProperties(() => $firstProperties?.data?.data || []);
        setIsLoading(() => false);

        const customEvent = new CustomEvent("requestFinished", {
          detail: {
            timestamp: performance.now(),
          },
        });
        document.dispatchEvent(customEvent);
        setTotalproperties(
          () => $firstProperties?.data?.meta_data?.total_records
        );

        return;
      }

      if ($storageLoaded && $parityLoaded) {
        // Otherwise, merge storageData and eanProperties results
        const $properties = $storageProperties?.data?.data.map((elem) => {
          const property = $eanProperties?.data?.data.find(
            (prop) =>
              prop.id === elem.id &&
              (prop.rate.low.total?.nightly_avg <
                elem.rate.low?.total?.nightly_avg ||
                (prop.rate.low.total !== false &&
                  elem.rate.low?.total === false))
          );
          return property ? property : elem;
        });
        setProperties(() => $properties || []);

        setIsLoading(() => false);

        const customEvent = new CustomEvent("requestTotalFinished", {
          detail: {
            timestamp: performance.now(),
          },
        });
        document.dispatchEvent(customEvent);
      }

      if ($firstLoaded) {
        setTotalproperties(
          () => $firstProperties?.data?.meta_data?.total_records
        );
      }
    }
  }, [
    $firstLoaded,
    $storageLoaded,
    $parityLoaded,
    $firstFetching,
    $storageFetching,
    $parityFetching,
  ]);

  const refetch = (newParams) => {
    setIsLoading(true);
    setProperties([]);
    const updatedParams = { ...params, ...newParams };
    $session.current = {
      ...$session.current,
      ...updatedParams,
      rooms: updatedParams.units,
      promo_code: updatedParams.promoCode,
    };
    setParams(updatedParams);

    window._session = JSON.stringify($session.current);    
  };

  return (
    <PropertiesContext.Provider
      value={{
        properties,
        propsIsLoading: !$storageLoaded || !$parityLoaded || !$firstLoaded,
        refetch,
        $session: $session.current,
        discounts: $discounts?.data.data,
        filters,
        urlFilters,
        totalProperties,
        allowPagination: allowPagination.current,
        showSoldOut: showSoldOut.current,
      }}
    >
      {children}
    </PropertiesContext.Provider>
  );
};

export const useProperties = () => useContext(PropertiesContext);
