import React, { useEffect, useState } from "react";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { useProperties } from "../context/properties.context";
import { Tooltip } from "antd";
import useIsMobile from "../hooks/useIsMobile";

export default function Occupancy({ updateOcuppancy, compareUnits, sbUnits }) {
  const { $session } = useProperties();
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const [units, setUnits] = useState($session.rooms || [{ adults: 2, children: 0 }]);
  const [hasToUpdate, setHasToUpdate] = useState(false);
  const isMobile = useIsMobile();

  const openFilters = () => {
    setIsVisible(!isVisible);

    if (!isVisible) {
      document.querySelector('body').style.overflowY = 'hidden';
    } else {
      document.querySelector('body').style.overflowY = null;
    }
  };

  const applyFilters = () => {
    updateOcuppancy(units, hasToUpdate);
    openFilters();
  };

  const addUnit = () => {
    setUnits([...units, { adults: 2, children: 0 }]);
    setHasToUpdate(!compareUnits(sbUnits,  [...units, { adults: 2, children: 0 }]));
  };

  const updateUnit = (index, field, value) => {
    const newUnits = structuredClone(units);
    newUnits[index][field] = value;
    setHasToUpdate(!compareUnits(sbUnits,  newUnits));
    setUnits(newUnits);
  };

  const showUnitAmount = globalSettings.show_units_amount;

  const getTotalAdults = () =>
    units.reduce((total, unit) => total + unit.adults, 0);

  const getTotalChildren = () =>
    units.reduce((total, unit) => total + unit.children, 0);

  const getTotalUnits = () => units.length;

  const removeUnit = (index) => {
    const newUnits = units.filter((_, unitIndex) => unitIndex !== index);
    setHasToUpdate(!compareUnits(sbUnits,  newUnits));
    setUnits(newUnits);
  };

  const classDiv = `main-search_occupancy ${isVisible ? "active" : ""}`;

  return (
    <>
      <div ref={ref} className={classDiv}>
        <div className="occupancy_adults" onClick={openFilters}>
          <label>Adults</label>
          <span>{getTotalAdults()}</span>
        </div>

        <div className="occupancy_children" onClick={openFilters}>
          <label>Children</label>
          <span>{getTotalChildren()}</span>
        </div>

        {showUnitAmount && (
          <div className="occupancy_rooms" onClick={openFilters}>
            <label>Units</label>
            <span>{getTotalUnits()}</span>
          </div>
        )}

        <div className="occupancy_filter" style={{...(isMobile ? {top: `${document.querySelector('.site-header').clientHeight - 1}px`} : {})}}>
          <div className="occupancy_filter-container">
            <h3>
              {showUnitAmount ? (
                <>
                  <span> How many guests (per unit)?</span>
                  <Tooltip
                    title='This selection allows you to book multiple of the same "unit," such as three separate hotel rooms or condos.'
                    placement="bottom"
                  >
                    <svg
                      className="icon-info"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" />
                    </svg>
                  </Tooltip>
                </>
              ) : (
                <span> How many guests?</span>
              )}
            </h3>

            {units.map((unit, index) => (
              <div key={index} className="room">
                <div className="room-header">
                  <h4
                    className="room-header_title"
                    data-ref="room-header_title"
                  >
                    Unit {index + 1}
                  </h4>
                  {index > 0 && (
                    <span
                      className="room-header_subtract room-counter"
                      onClick={() => removeUnit(index)}
                    >
                      Remove
                    </span>
                  )}
                </div>
                <div className="guest-num guest-num-adults">
                  <label>Adults</label>
                  <div className="occupancy-counter">
                    <svg
                      className="icon icon-down-count"
                      onClick={() =>
                        updateUnit(
                          index,
                          "adults",
                          Math.max(1, unit.adults - 1)
                        )
                      }
                    >
                      <use xlinkHref="#icon-down-count"></use>
                    </svg>

                    <span>{unit.adults}</span>

                    <svg
                      className="icon icon-up-count"
                      onClick={() =>
                        updateUnit(index, "adults", unit.adults + 1)
                      }
                    >
                      <use xlinkHref="#icon-up-count"></use>
                    </svg>
                  </div>
                </div>
                <div className="guest-num guest-num-children">
                  <label>Children</label>
                  <div className="occupancy-counter">
                    <svg
                      className="icon icon-down-count"
                      onClick={() =>
                        updateUnit(
                          index,
                          "children",
                          Math.max(0, unit.children - 1)
                        )
                      }
                    >
                      <use xlinkHref="#icon-down-count"></use>
                    </svg>

                    <span>{unit.children}</span>

                    <svg
                      className="icon icon-up-count"
                      onClick={() =>
                        updateUnit(index, "children", unit.children + 1)
                      }
                    >
                      <use xlinkHref="#icon-up-count"></use>
                    </svg>
                  </div>
                </div>
              </div>
            ))}

            {showUnitAmount && (
              <div className="room-multiplier room-counter">
                <span onClick={addUnit}> + Add unit</span>
              </div>
            )}

            <div className="occupancy_filter__update occupancy-filters-update">
              <button type="button" className="cancel" onClick={openFilters}>
                Cancel
              </button>
              <button type="button" className="apply" onClick={applyFilters}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>

      <span className="occupancy_mobile mobile" onClick={openFilters} style={{color: globalSettings.single_hotel_pin_color}}>
        {getTotalAdults() + getTotalChildren()} Guests &mdash; {getTotalUnits()}{" "}
        Units
      </span>
    </>
  );
}
