import React, { useState } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";

const Sort = ({ sortOptions, applyFilters }) => {
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const [sortSelected, setSortSelected] = useState("");

  const toggleListVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleSortClick = (item) => {
    setSortSelected(item.name);
    applyFilters({ sort_by: item?.name?.toLowerCase() });
    toggleListVisibility();
  };

  const classDiv = `filter_sort ${isVisible ? "active" : ""}`;

  const calculateIconClass = (item) => {
    return sortSelected.includes(item.name) ? "icon active" : "icon";
  };

  return (
    <div ref={ref} className={classDiv}>
      <h3
        className="filter_heading"
        data-group="filters"
        onClick={toggleListVisibility}
      >
        Sort
        <span className="drop-indicator">
          <svg className="icon icon-chevron-down">
            <use xlinkHref="#icon-chevron-down"></use>
          </svg>
        </span>
      </h3>

      <div className="filter_list">
        <ul>
          {sortOptions &&
            sortOptions.map((item, idx) => {
              return (
                <li
                  key={`sort-${idx}`}
                  data-group="sort-items"
                  className="sort"
                  onClick={() => handleSortClick(item)}
                >
                  <svg
                    className={calculateIconClass(item)}
                    data-group="check-mark"
                  >
                    <use
                      xlinkHref={
                        sortSelected.includes(item.name)
                          ? "#icon-check-active"
                          : "#icon-check-empty"
                      }
                    ></use>
                  </svg>
                  <span>{item.name}</span>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default Sort;
