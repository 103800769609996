import React from "react";
import ContentLoader from "react-content-loader";

const FilterLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={700}
      height={150}
      viewBox="0 0 700 150"
      backgroundColor="#c8c8c8"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="20" y="20" rx="4" ry="4" width="220" height="20"></rect>
      <rect x="400" y="20" rx="4" ry="4" width="140" height="20"></rect>
      <rect x="580" y="20" rx="4" ry="4" width="110" height="20"></rect>
      <rect x="20" y="50" rx="4" ry="4" width="80" height="20"></rect>
      <rect x="110" y="50" rx="4" ry="4" width="80" height="20"></rect>
      <rect x="210" y="50" rx="4" ry="4" width="80" height="20"></rect>
      <rect x="310" y="50" rx="4" ry="4" width="80" height="20"></rect>
      <rect x="410" y="50" rx="4" ry="4" width="80" height="20"></rect>
      <rect x="510" y="50" rx="4" ry="4" width="80" height="20"></rect>
      <rect x="610" y="50" rx="4" ry="4" width="80" height="20"></rect>
    </ContentLoader>
  );
};

export default FilterLoader;
