import React, { useState, useEffect } from 'react'

export function paginateProperties(properties, currentPage = 1, pageSize = 20) {
  const _props = structuredClone(properties);
  const sliceIndex = currentPage - 1;
  return _props.slice(sliceIndex * pageSize, (sliceIndex + 1) * pageSize);
}

export default function Pagination({ currentPage = 1, totalProps, changePage }) {
  const [curr, setCurr] = useState(currentPage || 1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setTotalPages(() => Math.ceil(totalProps / 20));
  }, [totalProps])

  function setPage(page) {
    setCurr((prevState) => {changePage(page); return page})
  }

  return (
    <div className="list-pagination" id="pagination">
      <div className="list-pagination_wrap">
        <button disabled={curr === 1} onClick={() => setPage(1)} id="pageFirst">&laquo;</button>
        <button disabled={curr === 1} onClick={() => setPage(curr - 1)} id="pagePrev">&lsaquo;</button>
        <button disabled={curr === totalPages || !totalPages} onClick={() => setPage(curr + 1)} id="pageNext">&rsaquo;</button>
        <button disabled={curr === totalPages || !totalPages} onClick={() => setPage(totalPages)} id="pageLast">&raquo;</button>
      </div>
    </div>    
  )
}
