// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.srp-modal {
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.srp-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.srp-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 720px;
  pointer-events: all;
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translate3d(-50%, 0, 0);
  z-index: 2;
}

.srp-close-button {
  color: #fff;
  float: right;
  font-size: 22px;
  font-weight: bold;
  border-radius: 50%;
  background: #000;
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  top:-10px;
  right: -10px;
  padding: 0;
}

.srp-close-button:hover,
.srp-close-button:focus {
  background: #444;
  text-decoration: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./components/modal/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,UAAU;EACV,OAAO;EACP,MAAM;EACN,SAAS;EACT,QAAQ;AACV;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,kCAAkC;EAClC,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,UAAU;AACZ;;AAEA;;EAEE,gBAAgB;EAChB,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":[".srp-modal {\n  position: fixed;\n  z-index: 100000;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n\n.srp-modal-backdrop {\n  background-color: rgba(0, 0, 0, 0.7);\n  position: absolute;\n  z-index: 1;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  right: 0;\n}\n\n.srp-modal-content {\n  background-color: #fff;\n  padding: 20px;\n  border-radius: 10px;\n  width: 90%;\n  max-width: 720px;\n  pointer-events: all;\n  position: absolute;\n  left: 50%;\n  top: 15%;\n  transform: translate3d(-50%, 0, 0);\n  z-index: 2;\n}\n\n.srp-close-button {\n  color: #fff;\n  float: right;\n  font-size: 22px;\n  font-weight: bold;\n  border-radius: 50%;\n  background: #000;\n  width: 32px;\n  height: 32px;\n  display: block;\n  position: absolute;\n  top:-10px;\n  right: -10px;\n  padding: 0;\n}\n\n.srp-close-button:hover,\n.srp-close-button:focus {\n  background: #444;\n  text-decoration: none;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
