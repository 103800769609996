import React, { useState } from "react";

export default function PropertyGallery({
  property_image,
  property_description,
  permalink,
  name,
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [startTouch, setStartTouch] = useState(null);
  const session = JSON.parse(_session);
  
  const affiliateUrl = session?.affiliate
  ? "?affiliate=" + session?.affiliate
  : "";

  const setGalleryStyle = () => {
    const defaultBackground = "none";
    const backgroundImage = property_image?.[currentImageIndex]?.url
    ? `url(${property_image[currentImageIndex].url}?tr=w-auto,h-320) no-repeat center center / cover`
    : defaultBackground;
  
    return {
      background: backgroundImage,
    };
  }

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setStartTouch({ x: touch.clientX });
  };

  const handleTouchMove = (e) => {
    if (!startTouch) return;

    const touch = e.touches[0];
    const diffX = startTouch.x - touch.clientX;

    if (diffX > 50) {
      nextImage();
      setStartTouch(null);
    } else if (diffX < -50) {
      prevImage();
      setStartTouch(null);
    }
  };

  const handleTouchEnd = () => {
    setStartTouch(null);
  };

  const nextImage = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % property_image.length
      );
      setFade(true);
    }, 300);
  };

  const prevImage = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? property_image.length - 1 : prevIndex - 1
      );
      setFade(true);
    }, 300);
  };

  return (
    <div className="property-card_imgblock">
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className={`img-gallery ${fade ? "fade-in" : "fade-out"}`}
        style={setGalleryStyle()}
      >
        {property_description.staff_pick && (
          <div className="staff-pick">
            <svg
              className={`icon ${globalSettings?.staff_pick_icon}`}
              title={globalSettings?.staff_pick_name}
              rel="tooltip"
            >
              <use xlinkHref={`#${globalSettings?.staff_pick_icon}`}></use>
            </svg>
            <span>{property_description.staff_pick}</span>
          </div>
        )}
        <a
          href={`/${permalink}`}
          target="_blank"
          className="img-gallery_link property_link"
          data-prop_name={name}
        >
          <span className="hidden-text assistive-text">&nbsp;</span>
        </a>
      </div>
      <ul className="direction-nav">
        <li className="prev" onClick={prevImage}>
          <svg className="icon">
            <use xlinkHref="#icon-prev"></use>
          </svg>
        </li>
        <li className="next" onClick={nextImage}>
          <svg className="icon">
            <use xlinkHref="#icon-next"></use>
          </svg>
        </li>
      </ul>
    </div>
  );
}
