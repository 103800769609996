import React, { useEffect, useState } from "react";
import FeatureFilters from "./filters/feature-filters";
import PropertyTypes from "./filters/property-types";
import PropertySearch from "./property-search";
import Amenities from "./filters/amenities";
import Areas from "./filters/areas";
import Rating from "./filters/rating";
import Sort from "./filters/sort";

import { useQueryClient } from "@tanstack/react-query";

const PropertyFilters = ({
  clear,
  filters,
  applyFilters,
  openFilterMobile,
  setOpenFilterMobile,
  clearFilters,
}) => {
  const queryClient = useQueryClient();

  const properties = queryClient.getQueryData(["storageData"]);

  const sortOptions = () => {
    let sorts = [];
    for (let i = 0; i < globalSettings.allowed_filters.sort_by.length; i++) {
      let obj = {
        name: globalSettings.allowed_filters.sort_by[i],
        state: 0,
      };
      if (sorts.length === 0) {
        obj.state = 1;
      }
      sorts.push(obj);
    }
    return sorts;
  };

  const classPropertyFilters = `property-filters ${
    openFilterMobile ? "active" : ""
  }`;
  
  const cancelClear = () => {
    clearFilters();
    setOpenFilterMobile(!openFilterMobile);
  };

  return (
    <>
      <div className={classPropertyFilters}>
        <FeatureFilters
          clear={clear}
          features={filters.features}
          properties={properties}
          applyFilters={applyFilters}
        />

        <PropertyTypes
          clear={clear}
          propertyTypes={filters.property_types}
          applyFilters={applyFilters}
        />

        <Areas applyFilters={applyFilters} clear={clear} areas={filters.areas} />

        <Amenities
          amenities={filters.amenities}
          applyFilters={applyFilters}
          clear={clear}
        />

        <Rating
          ratings={filters.rating}
          applyFilters={applyFilters}
          clear={clear}
        />

        <Sort
          sortOptions={sortOptions()}
          applyFilters={applyFilters}
          clear={clear}
        />

        <PropertySearch applyFilters={applyFilters} />

        <div className="property-filters-update mobile-filters-update">
          <button className="cancel" onClick={() => cancelClear()}>
            Cancel
          </button>

          <button
            className="apply"
            onClick={() => setOpenFilterMobile(!openFilterMobile)}
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
};

export default PropertyFilters;
