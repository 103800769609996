import React, { useState, useEffect } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useProperties } from "../../context/properties.context";

const Areas = ({ clear, applyFilters, areas }) => {
  const { urlFilters } = useProperties();
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const [areasSelected, setAreasSelected] = useState([]);

  useEffect(() => {
    if (clear) {
      setAreasSelected([]);

      return;
    }

    if (Object.keys(urlFilters).length && urlFilters.areas) {
      setAreasSelected(urlFilters.areas);
    }
  }, [clear]);

  const toggleListVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleAreaClick = (item, children) => {
    let areasList = [];
    let id = parseInt(item.id);

    if (areasSelected.includes(id)) {
      areasList = areasSelected.filter((f) => f !== id);
      
      if (children) {
        const childrenIds = children.map((child) => parseInt(child.id));
        areasList = areasList.filter(area => !childrenIds.includes(area));
      }      
    } else {
      areasList = [...areasSelected, id];
      
      if (children) {
        const childrenIds = children.map((child) => parseInt(child.id));
        areasList = [...areasList, ...childrenIds];
      }
    }

    setAreasSelected(areasList);
    applyFilters({ areas: areasList });
  };

  const calculateIconClass = (item) => {
    return areasSelected.includes(item.name) ? "icon active" : "icon";
  };

  const classDiv = `filter_neighborhoods ${isVisible ? "active" : ""}`;

  return (
    <div ref={ref} className={classDiv}>
      <h3
        className="filter_heading"
        data-group="filters"
        onClick={toggleListVisibility}
      >
        Areas
        {areasSelected.length > 0 && (
          <span className="filter-badge">{areasSelected.length}</span>
        )}
        <span className="drop-indicator">
          <svg className="icon icon-chevron-down">
            <use xlinkHref="#icon-chevron-down"></use>
          </svg>
        </span>
      </h3>
      <div className="filter_list wide">
        <ul>
          {areas && areas.map((area, idx) => {
            return (
              <li
                key={`area-${area.id}`}
                data-group="filter-items"
                className="area"
                data-state="0"
                data-family="parent"
                data-id={`area-${area.id}`}
              >
                <div
                  className="area"
                  onClick={() => handleAreaClick(area, area?.children)}
                >
                  <svg
                    className={calculateIconClass(area)}
                    data-group="check-mark"
                  >
                    <use
                      xlinkHref={
                        areasSelected.includes(area.id)
                          ? "#icon-check-active"
                          : "#icon-check-empty"
                      }
                    ></use>
                  </svg>
                  <span>{area.name}</span>
                </div>

                {area.children && area.children.length > 0 && (
                  <ul style={{paddingTop: '.5rem'}}>
                    {area.children.map((childArea, idx) => {
                      return (
                        <li
                          key={`children-area-${childArea.id}`}
                          data-group="filter-items"
                          className="area"
                          data-id={`area-children-${childArea.id}`}
                          onClick={() => handleAreaClick(childArea)}
                        >
                          <svg
                            className={calculateIconClass(childArea)}
                            data-group="check-mark"
                          >
                            <use
                              xlinkHref={
                                areasSelected.includes(childArea.id)
                                  ? "#icon-check-active"
                                  : "#icon-check-empty"
                              }
                            ></use>
                          </svg>
                          <span>{childArea.name}</span>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Areas;
